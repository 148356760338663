var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{directives:[{name:"permission",rawName:"v-permission",value:({
      roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
      operator: '==='
    }),expression:"{\n      roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],\n      operator: '==='\n    }"}]},[_c('v-col',{staticClass:"text-right"},[_c('BaseButton',{attrs:{"id":"btn-create","name":"btn-create","color":"secondary","title":"Novo cadastro","icon":"fa-solid fa-plus"},on:{"click":_vm.create}})],1)],1),_c('BaseSearchPage',{attrs:{"title":"Consultar plano"},scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","xl":"3","lg":"6","md":"6","sm":"6"}},[_c('v-text-field',{attrs:{"id":"filter","name":"filter","label":"Nome do plano","dense":"","outlined":"","hide-details":"","clearable":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('v-col',{attrs:{"cols":"12","xl":"9","lg":"6","md":"6","sm":"6"}},[_c('BaseButton',{attrs:{"id":"btn-search","name":"btn-search","color":"primary","title":"Filtrar","icon":"fa-solid fa-magnifying-glass","height":"40"},on:{"click":_vm.search}})],1)],1)]},proxy:true}])}),_c('BaseTableList',{attrs:{"title":"Listagem de planos cadastrados"},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('v-data-table',{attrs:{"dense":"","disable-sort":"","item-key":"id","headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalRecords,"items-per-page":10,"footer-props":{
          itemsPerPageOptions: [10, 20, 50, 100],
          showFirstLastPage: true,
          firstIcon: 'first_page',
          lastIcon: 'last_page',
          prevIcon: 'chevron_left',
          nextIcon: 'chevron_right'
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.businessPlanName`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.businessPlanName)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"color":"primary","small":""}},on),[_vm._v(" fa-solid fa-circle-exclamation ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.createTooltipPlanName(item)))])])]}},{key:`item.situation`,fn:function({ item }){return [_c('span',{class:_vm.getColor(item.situation)},[_vm._v(_vm._s(item.situation))])]}},{key:`item.action`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.edit(item)}}},on),[_vm._v(" fa-solid fa-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Visualizar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({directives:[{name:"permission",rawName:"v-permission",value:({
                  roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
                  operator: '==='
                }),expression:"{\n                  roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],\n                  operator: '==='\n                }"}],attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.deletePlans(item)}}},on),[_vm._v(" fa-regular fa-trash-can ")])]}}],null,true)},[_c('span',[_vm._v("Excluir")])])]}}],null,true)})]},proxy:true}])}),_c('v-divider',{staticClass:"my-5"}),_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('BaseButton',{attrs:{"id":"btn-back","name":"btn-back","color":"primary","title":"Voltar","outlined":""},on:{"click":_vm.back}})],1)],1),(_vm.dialog)?_c('DialogPlans',{attrs:{"dialog":_vm.dialog,"title":_vm.dialogTitle,"edit":_vm.dialogEdit,"planId":_vm.planId,"document":_vm.$route.params.cnpj},on:{"closeModal":_vm.closeModal}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }