<template>
  <v-container>
    <v-row
      v-permission="{
        roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
        operator: '==='
      }"
    >
      <v-col class="text-right">
        <BaseButton
          id="btn-create"
          name="btn-create"
          color="secondary"
          title="Novo cadastro"
          icon="fa-solid fa-plus"
          @click="create"
        />
      </v-col>
    </v-row>

    <BaseSearchPage title="Consultar plano">
      <template v-slot:form>
        <v-row dense>
          <v-col cols="12" xl="3" lg="6" md="6" sm="6">
            <v-text-field
              id="filter"
              name="filter"
              label="Nome do plano"
              dense
              outlined
              hide-details
              clearable
              v-model="filter"
            />
          </v-col>
          <v-col cols="12" xl="9" lg="6" md="6" sm="6">
            <BaseButton
              id="btn-search"
              name="btn-search"
              color="primary"
              title="Filtrar"
              icon="fa-solid fa-magnifying-glass"
              height="40"
              @click="search"
            />
          </v-col>
        </v-row>
      </template>
    </BaseSearchPage>

    <BaseTableList title="Listagem de planos cadastrados">
      <template v-slot:table>
        <v-data-table
          dense
          disable-sort
          item-key="id"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalRecords"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right'
          }"
        >
          <template v-slot:[`item.businessPlanName`]="{ item }">
            {{ item.businessPlanName }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="primary" small>
                  fa-solid fa-circle-exclamation
                </v-icon>
              </template>
              <span>{{ createTooltipPlanName(item) }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.situation`]="{ item }">
            <span :class="getColor(item.situation)">{{ item.situation }}</span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="success"
                  class="mr-2"
                  small
                  @click="edit(item)"
                >
                  fa-solid fa-pencil
                </v-icon>
              </template>
              <span>Visualizar</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="error"
                  small
                  @click="deletePlans(item)"
                  v-permission="{
                    roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
                    operator: '==='
                  }"
                >
                  fa-regular fa-trash-can
                </v-icon>
              </template>
              <span>Excluir</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
    </BaseTableList>

    <v-divider class="my-5" />

    <v-row>
      <v-col class="text-right">
        <BaseButton
          id="btn-back"
          name="btn-back"
          color="primary"
          title="Voltar"
          outlined
          @click="back"
        />
      </v-col>
    </v-row>

    <DialogPlans
      v-if="dialog"
      :dialog="dialog"
      :title="dialogTitle"
      :edit="dialogEdit"
      :planId="planId"
      :document="$route.params.cnpj"
      @closeModal="closeModal"
    />
  </v-container>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { getColor } from '@/helpers/utils';
import { confirmMessage, showMessage } from '@/helpers/messages';
import { InsuranceCompanyService } from '@/services/api/insuranceCompany';

export default {
  mixins: [rulesMixin],

  components: {
    DialogPlans: () => import('./dialogs/dialog-plans')
  },

  data: () => ({
    items: [],
    totalRecords: 0,
    dialog: false,
    dialogTitle: '',
    dialogEdit: false,
    planId: {},
    filter: '',
    options: {},
    headers: [
      { text: 'Tipo de plano', value: 'planType' },
      { text: 'Plano', value: 'businessPlanName' },
      { text: 'Abrangência', value: 'coverage' },
      { text: 'Status', value: 'situation' },
      { text: '', value: 'action' }
    ]
  }),

  props: {
    editMode: {
      type: Boolean
    }
  },

  methods: {
    getColor,

    createTooltipPlanName(item) {
      let coparticipation = item.hasCoparticipation
        ? 'com coparticipação'
        : 'sem coparticipação';

      if (item.accommodation) {
        return `${item.accommodation} - ${coparticipation}`;
      } else {
        return `${coparticipation}`;
      }
    },

    async search() {
      try {
        const { page, itemsPerPage } = this.options;
        const insuranceCompanyService = new InsuranceCompanyService();
        const { status, data } = await insuranceCompanyService.getPlans({
          page,
          take: itemsPerPage,
          insuranceCompanyDocument: this.$route.params.cnpj,
          filter: this.filter
        });

        if (status === 200) {
          this.items = data.results;
          this.totalRecords = data.totalRecords;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async deletePlans(item) {
      try {
        const result = await confirmMessage(
          'Atenção!',
          'Deseja realmente excluir o registro selecionado?'
        );

        if (result) {
          const insuranceCompanyService = new InsuranceCompanyService();
          const { status } = await insuranceCompanyService.deletePlans({
            id: item.id,
            document: this.$route.params.cnpj
          });

          if (status === 204) {
            showMessage('success', 'Operação realizada com sucesso');
            this.search();
          }
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    back() {
      this.$emit('back');
    },

    create() {
      this.dialog = true;
      this.dialogTitle = 'Adicionar plano';
      this.dialogEdit = false;
      this.planId = {};
    },

    edit(item) {
      this.dialog = true;
      this.dialogTitle = 'Visualizar plano';
      this.dialogEdit = true;
      this.planId = Object.assign({}, item);
    },

    closeModal(search) {
      this.dialog = false;
      this.dialogTitle = '';
      this.dialogEdit = false;
      this.planId = {};

      if (search) {
        this.search();
      }
    }
  },

  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  }
};
</script>
